@import '@aurora/shared-client/styles/_variables.pcss';

.lia-search-container {
  display: flex;
  flex-direction: column;
  position: relative;

  &.lia-is-open .lia-embedded-search-form:after {
    content: '';
    width: 100%;
    z-index: 1040;
    position: absolute;
    height: 20px;
    bottom: -5px;
    background-color: var(--lia-bs-white);
  }
}

.lia-search-wrap {
  position: absolute;
  margin: 0 auto;
  padding-top: 5px;
  overflow: hidden;
  background-color: var(--lia-bs-white);
  box-shadow: 0 -5px 0 var(--lia-bs-white), var(--lia-bs-box-shadow);
  border-radius: 0 0 var(--lia-bs-border-radius-sm) var(--lia-bs-border-radius-sm);
  z-index: 1019;

  .lia-search-results:has(div > .lia-ai-button-inline) {
    padding-top: 0;
  }
  @media (--lia-breakpoint-down-md) {
    max-width: 100%;
  }
}

/* ensure that the search input is not covered by the embedded search results */
.lia-embedded-search-wrapper {
  z-index: 1041;
}

.lia-ai-button-inline {
  margin: 0 -10px 20px;
  width: calc(100% + 20px);
}
